<template>
    <div class="live-content">
        <div class="live-title">直播间调试</div>
        <div class="main-content">
            <div class="left-content">
                <div ref="liveVideo" class="live-video" id="live-video"></div>
            </div>
            <div class="right-content">
                <el-scrollbar class="right-top">
                    <div class="show-content">
                        <div class="speak-item" v-for="(item,index) in showText" :key="`speak_${index}`">{{item}}</div>
                    </div>
                </el-scrollbar>
                <div class="right-bottom">
                    <el-button type="primary" @click="startTest">开始测试</el-button>
                    <el-button type="danger" @click="endTest">结束测试</el-button>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        name: "LiveDebug",
        data() {
            return {
                watchNum: 0,
                showText: [],
                speechSDK: null,
            }
        },
        methods: {
            startTest() {
                let audioContext = new (window.AudioContext || window.webkitAudioContext)()
                let mediaStreamSource = null
                let scriptProcessor = null

                if (navigator.mediaDevices && navigator.mediaDevices.getUserMedia) {
                    // 获取用户的 media 信息
                    navigator.mediaDevices.getUserMedia({audio: true}).then((stream) => {
                        // 将麦克风的声音输入这个对象
                        mediaStreamSource = audioContext.createMediaStreamSource(stream)
                        // 创建一个音频分析对象，采样的缓冲区大小为4096，输入和输出都是单声道
                        scriptProcessor = audioContext.createScriptProcessor(4096, 1, 1)
                        // 将该分析对象与麦克风音频进行连接
                        mediaStreamSource.connect(scriptProcessor)
                        // 此举无甚效果，仅仅是因为解决 Chrome 自身的 bug
                        scriptProcessor.connect(audioContext.destination)

                        // 开始处理音频
                        // scriptProcessor.onaudioprocess = (e) => {
                        //     // 获得缓冲区的输入音频，转换为包含了PCM通道数据的32位浮点数组
                        //     let buffer = e.inputBuffer.getChannelData(0)
                        //     // 获取缓冲区中最大的音量值
                        //     let maxVal = Math.max.apply(Math, buffer)
                        //     // 显示音量值
                        // };
                        this.$refs.liveVideo.innerHTML = "";
                        this.setLivePusher()
                        this.setSpeech()
                    }).catch((error) => {
                      this.$message.warning('没有找到麦克风或摄像头设备')
                    })
                } else {
                    this.$message.warning('不支持获取媒体接口')
                }
            },
            endTest() {
                this.speechSDK && this.speechSDK.stop();
                if (this.$route.query.form) {
                    this.$router.go(-1)
                }
                this.$router.push('liveRoom')
            },
            setSpeech() {
                const params = {
                    signCallback: signCallback, // 鉴权函数
                    // 用户参数
                    secretid: config.secretId,
                    appid: config.appId,
                    // 实时识别接口参数
                    engine_model_type: "16k_zh", // 因为内置WebRecorder采样16k的数据，所以参数 engineModelType 需要选择16k的引擎，为 '16k_zh'
                    // 以下为非必填参数，可跟据业务自行修改
                    voice_format: 1,
                    hotword_id: "08003a00000000000000000000000000",
                    needvad: 1,
                    filter_dirty: 1,
                    filter_modal: 2,
                    filter_punc: 0,
                    convert_num_mode: 1,
                    word_info: 2,
                };
                this.speechSDK = new WebAudioSpeechRecognizer(params);
                this.speechSDK.OnRecognitionStart = (res) => {
                    console.log("开始识别", res);
                };
                // 一句话结束
                this.speechSDK.OnSentenceEnd = (res) => {
                    console.log("一句话结束", res);
                    if (res.voice_text_str) {
                        this.showText.push(res.voice_text_str)
                    }
                };
                // 识别结束
                this.speechSDK.OnRecognitionComplete = (res) => {
                    console.log("识别结束", res);
                };
                // 识别错误
                this.speechSDK.OnError = (res) => {
                    console.log("识别失败", res);
                };
                this.speechSDK.start();
            },
            setLivePusher() {
                this.livePusher = new TXLivePusher();
                const {livePusher} = this;
                livePusher.setRenderView("live-video");
                livePusher.setVideoQuality("720p");
                livePusher.setAudioQuality("standard");
                livePusher.setProperty("setVideoFPS", 30);
                const gatherStates = [false, false];
                const gatherCallback = (index) => {
                    gatherStates[index] = true;
                    const valid = !gatherStates.includes(false);
                };
                livePusher.startCamera();
                livePusher.startMicrophone();
                livePusher.setObserver({
                    onCaptureFirstAudioFrame: () => gatherCallback(0),
                    onCaptureFirstVideoFrame: () => gatherCallback(1),
                    onWarning: (code, msg) => {
                        const errMsgs = {
                            NotFoundError: "没有找到麦克风或摄像头设备",
                            NotAllowedError: "请确保给予麦克风和摄像头设备的权限",
                            common: "无法获取麦克风和摄像头设备，请尝试刷新网页",
                        };
                        let errmsg = errMsgs[msg];
                        errmsg = errmsg ? errmsg : errMsgs.common;
                    },
                });
            },
        }
    }
</script>

<style scoped lang="scss">
    .live-content {
        height: 100%;
        width: 100%;
        background-color: #070932;
        display: flex;
        flex-direction: column;

        .live-title {
            padding: 20px;
            font-size: 18px;
            color: #fff;
        }
    }

    .main-content {
        height: 100%;
        width: 100%;
        margin: 10px auto 60px;
        display: flex;
        justify-content: center;
    }

    .left-content {
        height: 100%;
        width: 26%;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        border-radius: 20px;
        background-image: url('../../assets/image/student/live_background.png');
        background-repeat: no-repeat;
        background-size: cover;
        position: relative;
    }

    .right-content {
        width: 40%;
        height: 100%;
        margin-left: 20px;
    }

    .right-top {
        height: 75%;
        background: #0C0E3F;
        border-radius: 10px;
        overflow: hidden;

        ::v-deep .el-scrollbar__wrap {
            height: 100%;
            overflow-x: hidden;

            .el-scrollbar__view {
                height: 100%;
                display: flex;
                flex-direction: column;
            }
        }

        .show-content {
            margin: 20px;
            color: #fff;
            opacity: 0.3;
            line-height: 24px;
            height: calc(100% - 40px);
        }
    }

    .right-bottom {
        margin-top: 20px;
        height: calc(25% - 20px);
        background: #0C0E3F;
        border-radius: 10px;
        overflow: hidden;
        display: flex;
        align-items: center;
        justify-content: center;
    }

    .live-video {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;

        ::v-deep video {
            width: 100%;
            height: 100%;
            object-fit: cover !important;
        }
    }
</style>